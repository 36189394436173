header {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

nav {
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #54a6ff;
    background-image:
        radial-gradient(#63adff 20%, transparent 0), 
        radial-gradient(#63adff 20%, transparent 0);
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    border-top: 4px solid #86c6ff;
    border-bottom: 4px solid #86c6ff;
}

nav ul {
    padding: 0;
    width: 80vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

nav ul li {
    width: 150px;
    display: flex;
    justify-content: center;
    font-family: 'Cairo', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    color: #ffffff;
    list-style-type: none;
    border: 4px solid transparent;
    border-radius: 10px;
}

nav ul li:hover {
    transform: rotate(0deg);
}

.center {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.nameAndLogo {
    width: 500px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.navHome {
    transform:rotate(-5deg);
    background-color: #d88986;
    border-color: #ebbbb8;
}

.navAbout {
    transform:rotate(3deg);
    background-color: #7bc572;
    border-color: #a7df9f;
}

.navProjects {
    transform:rotate(2deg);
    background-color: #ded15e;
    border-color: #e7df95;
}

.navMeetups {
    transform:rotate(-2deg);
    background-color: #3c5eab;
    border-color: #4878e7;
}

.navHome:hover {
    background-color: #e7b3b1;
}

.navAbout:hover {
    background-color: #c3f0be;
}

.navProjects:hover {
    background-color: #e7e2b3;
}

.navMeetups:hover {
    background-color: #4670d1;
}


/* Smart Phones */
@media screen and (max-width: 40em){
    nav ul {
        width: 100vw;
    }
    nav ul li {
        font-size: 5vw;
    }

    .nameAndLogo {
        width: 380px;
        justify-content: flex-start;
    }

    .navHome, .navAbout, .navProjects, .navMeetups {
        transform:rotate(0deg);
        width: 47vw;
        border-radius: 0;
    }

    .navPenguin {
        display: none;
        visibility:hidden;
    }
}
