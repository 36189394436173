footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #2D2B29;
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 18px;
}