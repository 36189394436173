

.welcomeHome {
    display: flex;
    justify-content: center;
    font-size: 50px;
}

.resumeButton {
    cursor: pointer;
    border: none;
    background: #1fc186cc;
    border: 4px solid #5eebb7cc;
    font-family: 'Cairo', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 0px;
    color: white;;
    height: 55px;
    width: 300px;
    border-radius: 100px;
}

.resumeButton:hover{
    background: #34cf96cc;
}

.resumeButton:active{
    background: #38f5afcc;
    border: 4px solid #1fc186cc;
}

/* Smart Phones */
@media screen and (max-width: 40em){
    .welcomeHome {
        font-size: 22px;
    }
    .resumeButton {
        margin-top: 20px;
        width: 100%;
        font-size: 24px;
        border-radius: 0;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-width: 4px;
    }
}