body {
    background-color: #2398ff;
    background-image:
        radial-gradient(#0d7eff 10%, transparent 0), 
        radial-gradient(#0d7eff 10%, transparent 0);
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    background-attachment: fixed;
    margin: 0 0;
}

h1 {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 60px;
    color: white;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #2D2B29;
}

h2 {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 38px;
    color: #2D2B29;
}

h3 {
    font-family: 'EB Garamond', normal;
    font-size: 24px;
    color: #2D2B29;
}

p {
    margin: 0 2vw;
    font-family: 'EB Garamond', normal;
    font-size: 24px;
    color: #2D2B29;
}

a {
    text-decoration: none;
    color: inherit;
}

section {
    width: 100%;
}

span {
    font-family: 'EB Garamond', normal;
    color: white;
}

.pictureLeft {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-bottom: 3vw;
}

.threeColumns {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-bottom: 40px;
}

.column {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
}

.emailMe, .emailMobile{
    font-family: 'Cairo', sans-serif;
    font-size: 100%;
    border: 3px solid #a8ceff;
    background: #3f5c99;
    padding: 1vh 1vw;
    font-weight: bold; 
    border-radius: 50px;
}

.emailMe:hover, .emailMobile:hover {
    background: #33497a;
}

.emailMe:active, .emailMobile:active {
    background: #1b81e0;
    border-color: #6286bd;
}

.emailMobile {
    display: none;
    width: 96%;
    height: 30px;
    justify-content: center;
    align-items: center;
}

.aboutMe {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
}

.link {
    color: #57c9e9;
}


.buttonSvg {
    width: 400%;
}

.invert {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.aboutTitle {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timeline {
    margin-bottom: 60px;
}

/* Smart Phones */
@media screen and (max-width: 40em){
    h1 {
        font-size: 30px;
        -webkit-text-stroke-width: 1px;
    }
    h2 {
        text-align: center;
        font-size: 22px;
    }

    .pictureLeft, .threeColumns {
        flex-flow: column wrap;
        align-items: none;
    }

    p {
        font-size: 18px;
    }

    .emailMe {
        display: none;
    }
    
    .emailMobile {
        display: flex;
        margin-bottom: 10px;
        border-radius: 0;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    .aboutTitle {
        justify-content: center;
    }

    .articlePicture {
        width: 80vw;
    }
}
header {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

nav {
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #54a6ff;
    background-image:
        radial-gradient(#63adff 20%, transparent 0), 
        radial-gradient(#63adff 20%, transparent 0);
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    border-top: 4px solid #86c6ff;
    border-bottom: 4px solid #86c6ff;
}

nav ul {
    padding: 0;
    width: 80vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

nav ul li {
    width: 150px;
    display: flex;
    justify-content: center;
    font-family: 'Cairo', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    color: #ffffff;
    list-style-type: none;
    border: 4px solid transparent;
    border-radius: 10px;
}

nav ul li:hover {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.center {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.nameAndLogo {
    width: 500px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.navHome {
    -webkit-transform:rotate(-5deg);
            transform:rotate(-5deg);
    background-color: #d88986;
    border-color: #ebbbb8;
}

.navAbout {
    -webkit-transform:rotate(3deg);
            transform:rotate(3deg);
    background-color: #7bc572;
    border-color: #a7df9f;
}

.navProjects {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
    background-color: #ded15e;
    border-color: #e7df95;
}

.navMeetups {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
    background-color: #3c5eab;
    border-color: #4878e7;
}

.navHome:hover {
    background-color: #e7b3b1;
}

.navAbout:hover {
    background-color: #c3f0be;
}

.navProjects:hover {
    background-color: #e7e2b3;
}

.navMeetups:hover {
    background-color: #4670d1;
}


/* Smart Phones */
@media screen and (max-width: 40em){
    nav ul {
        width: 100vw;
    }
    nav ul li {
        font-size: 5vw;
    }

    .nameAndLogo {
        width: 380px;
        justify-content: flex-start;
    }

    .navHome, .navAbout, .navProjects, .navMeetups {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
        width: 47vw;
        border-radius: 0;
    }

    .navPenguin {
        display: none;
        visibility:hidden;
    }
}



.welcomeHome {
    display: flex;
    justify-content: center;
    font-size: 50px;
}

.resumeButton {
    cursor: pointer;
    border: none;
    background: #1fc186cc;
    border: 4px solid #5eebb7cc;
    font-family: 'Cairo', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 0px;
    color: white;;
    height: 55px;
    width: 300px;
    border-radius: 100px;
}

.resumeButton:hover{
    background: #34cf96cc;
}

.resumeButton:active{
    background: #38f5afcc;
    border: 4px solid #1fc186cc;
}

/* Smart Phones */
@media screen and (max-width: 40em){
    .welcomeHome {
        font-size: 22px;
    }
    .resumeButton {
        margin-top: 20px;
        width: 100%;
        font-size: 24px;
        border-radius: 0;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-width: 4px;
    }
}
.socialMedia {
    display: flex;
    justify-content: space-around;
    padding: 40px 10vw;
}

.twitter, .github, .youtube, .linkedIn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border: 4px solid black;
    border-radius: 100%;
}

.twitter {
    background-color: #3c5eab;
    border-color: #638ceb;
}

.github {
    background-color: #7bc572;
    border-color: #a7df9f;
}

.youtube {
    background-color: #d88986;
    border-color: #ebbbb8;
}

.linkedIn {
    background-color: #5a9cda;
    border-color: #9eceeb;
}

.twitter:hover {
    background-color: #4670d1;
}

.github:hover {
    background-color: #72d168;
}

.youtube:hover {
    background-color: #ec9996;
}

.linkedIn:hover {
    background-color: #66a6e2;
}

main {
    margin: 20px 10vw;
    padding: 10px 10vw; 
    background:url(
        data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAIUlEQVQYV2P8////fwY0wAgTfPiDgUGeAyILF0RWTLwgAEuZFfLl0hmXAAAAAElFTkSuQmCC) repeat;
    border: solid 10px white;
    border-radius: 2px;
}

/* Smart Phones */
@media screen and (max-width: 40em){
    main {
        margin: 20px 0;
        padding: 10px 0;
    }
}
footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #2D2B29;
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 18px;
}
